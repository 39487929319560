<template>
    <v-row no-gutters>
        <v-col class="main-col" lg="7">
            <v-container class="pa-4 pa-sm-12 pt-sm-8">
                <v-card width="600px" elevation="0" rounded class="pa-4 pa-sm-6">
                    <img class="mb-10" style="width: 150px;" width="232px;" :src="require(`@/assets/svg/voiceform_full_black.svg`)"/>
                    <div class="text-h5 text-md-h4 mb-6">
                        Reset Password
                    </div>
                    <v-form 
                        ref="form" 
                        v-model="valid"
                        lazy-validation
                        @submit.prevent="handleResetPassword"
                    >
                        <div class="text-subtitle-1 mb-4">Email Address</div>
                        <v-text-field 
                            hide-details="auto" 
                            label="Email Address" 
                            filled
                            readonly
                            v-model="form.email"
                            class="mb-4"
                            outlined
                        ></v-text-field>
                        <div class="text-subtitle-1 mb-4">Password</div>
                        <v-text-field 
                            hide-details="auto" 
                            label="New password" 
                            v-model="form.password"
                            @keyup.enter="onKeyupEnter($event, 3)"
                            class="mb-4"
                            outlined
                            :type="showPassword ? 'text' : 'password'"
                            :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                            @click:append="showPassword = !showPassword"
                            :rules="passwordRules"
                            validate-on-blur
                            :error-messages="this.errors.password"
                        ></v-text-field>
                        <v-text-field 
                            hide-details="auto" 
                            label="Confirm new password" 
                            v-model="form.password_confirmation"
                            class="mb-4"
                            outlined
                            :type="showConfirmPassword ? 'text' : 'password'"
                            :append-icon="showConfirmPassword ? 'mdi-eye-off' : 'mdi-eye'"
                            @click:append="showConfirmPassword = !showConfirmPassword"
                            @keyup.enter="handleResetPassword"
                            :rules="passwordConfirmationRules"
                            validate-on-blur
                            :error-messages="this.errors.password"
                        ></v-text-field>
                        <v-btn
                            class="mt-5"
                            color="primary"
                            @click="handleResetPassword"
                            @click.prevent
                            large
                            elevation="0"
                            :loading="loading"
                            :width="$vuetify.breakpoint.xs? '100%': '300px'"
                        >
                            Reset Password 
                        </v-btn>
                    </v-form>
                </v-card>
            </v-container>
        </v-col>
        <v-col lg="5" class="d-none d-lg-block sub-col pa-10">
            <div class="ma-auto">
                <img class="mt-10 illustration" style="width: 100%" width="487px" :src="require(`@/assets/svg/illustration.svg`)"/>
            </div>
        </v-col>
    </v-row>
    <!-- TODO: Add voiceform trademark and contact info links at bottom of login, signup, reset and forgot password pages -->
</template>

<script>
import Api from "../api/Api"
import { mapMutations } from "vuex";

export default {
    name: "ResetPassword",
    data() {
        return {
            valid: true,
            loading: false,
            form: {
                email: null,
                password: null,
                password_confirmation: null,
                token: null,
            },
            errors: {},
            showPassword: false,
            showConfirmPassword: false,
            passwordRules: [
                value => !!value || 'Required',
                value => (value && value.length>=8) || "The password must be at least 8 characters long.",
                value => (value && /^(?=.*[0-9])(?=.*[a-zA-Z])/.test(value) ) || "The password must be alphanumeric.",
            ],
            passwordConfirmationRules: [
                value => !!value || 'Required',
            ],
        };
    },
    watch: {
        'form.password': function (){
            if(this.errors && this.errors.password){
                this.errors.password = null
            }
        },
        'form.password_confirmation': function (){
            if(this.errors && this.errors.password){
                this.errors.password = null
            }
        },
    },
    methods: {
        ...mapMutations(['showSnackBar']),
        async handleResetPassword() {
            // TODO: Expire token once password has been successfully reset so that user can't use the same link to reset password
            const valid = this.$refs.form.validate();

            if(!valid) {
                return;
            }

            if(this.form.password !== this.form.password_confirmation){
                return this.errors = {...this.errors, password: "Password doesn't match."}
            }
            
            this.loading = true;
            const { email, password, password_confirmation, token } = this.form;
            let response = await Api.resetPassword({
                email,
                password,
                password_confirmation,
                token,
            }).catch((e) => {
                this.loading = false;
                this.errors = e.response.data.errors;
            });
            if (response && response.status === 200) {
                this.errors = {};
                this.showSnackBar({
                    color: "success",
                    timeout: 6000,
                    text: "Password Reset Confirmed",
                });
                setTimeout(() => {
                    this.$router.push({ name: "login" })
                }, 2000);
            }
        },
        onKeyupEnter(e, offset=2) {
            const form = e.target.form;
            const index = Array.prototype.indexOf.call(form, e.target);
            form.elements[index + offset].focus();
            e.preventDefault();
        },
    },
    mounted() {
        this.form.email = this.$route.query.email;
        this.form.token = this.$route.query.token;
    }
};
</script>

<style lang="scss">
    :root {
        --overflow: hidden;
    }
</style>

<style scoped lang="scss">
    .sub-col {
        background-color: var(--v-primary-lighten5);
        overflow: hidden;
    }
    .main-col{
        position: relative;
        box-shadow: -20px 0px 30px 75px rgba(0, 0, 0, 0.05);
        height: 100vh;
        overflow: scroll;
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */
    }
    .main-col::-webkit-scrollbar { 
        display: none;  /* Safari and Chrome */
    }
    .illustration{
        margin-left: -100px;
    }
</style>
